import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation/Navigation';
import LoaderSecondary from '../components/Global/LoaderSecondary';
import { Animated } from "react-animated-css";
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { OCTAVIO_TOKEN } from '../const'
import axios from 'axios';

const MySearch = (props) => {
    let input;
    const handleClick = () => {
        props.onSearch(input.value);
    };
    return (
        <input
            id="searchCustom"
            className="form-control input-search custom-big-search"
            ref={n => input = n}
            type="text"
            placeholder="Buscar"
            onChange={handleClick}
        />
    );
};

class routeDeudores extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            rows: [],
        }

        this.searchBar          = this.searchBar.bind(this);
        this.handleFile          = this.handleFile.bind(this);
        this.handleSubmit          = this.handleSubmit.bind(this);

    }

    route(){
        return (
            <div className="bg-light pt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <label className="navigation-breadcrumb">Inicio &gt; Deudores</label>
                            <h5>Actualizacion de deudores</h5>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    searchBar(){
        return (
            <div className="navbarstiky sticky-top bg-white mb-4 pb-3 pt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="w-100 d-flex flex-row flex-xl-row flex-lg-row flex-md-row flex-sm-row justify-content-start align-items-center">
                              <label className="ml-3" htmlFor="deudores" style={{width:"30%"}}>Archivo deudores (.xslx)</label>
                              <input id="deudores" type="file" className="form-control" style={{height:"auto"}} name="deudores" onChange={this.handleFile}></input>
                              <a href="/Deudores.xlsx" download="ejemplo.xlsx" className="btn btn-primary ml-5">Ejemplo</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleFile(e){
      const validExtensions = ['.xls', '.xlsx'];
      if (validExtensions.includes(e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf('.')).toLowerCase())){
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const objects = rows.slice(1).map((row,i) => ({
            email: row[0],
            deudor: row[1],
            cliente: row[2],
            status: "pending",
            id: i 
          }));
          this.setState({ rows: objects });
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      } else {
        this.setState({ file: null, errorMessage: 'Please upload a valid Excel file (.xls or .xlsx).' });
      }
    }

    async handleSubmit(e){
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        ...this.state,
        loading: true,
      })
      for(let alumno of this.state.rows){
        alumno.status = 'loading';
        this.forceUpdate();
        let result = await this.updateUser(alumno);
        if(result.message == 'success'){
          alumno.status = 'success';
          alumno.respuesta = result.id
          this.forceUpdate();
        }else{
          alumno.status = 'error';
          alumno.respuesta = 'No encontrado'
          this.forceUpdate();
        }
      }
      this.setState({
        ...this.state,
        loading: false,
      })
    }
    
    async updateUser(usuario){
      return new Promise((res,rej)=>{
        let myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Auth-Token', OCTAVIO_TOKEN);
        myHeaders.append('cliente', usuario.cliente);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        let uri = encodeURI(`/usuarios?filter={"email":"${usuario.email}"}`)

        fetch(window.$UrlOctavio + uri, requestOptions)
            .then(response => response.json())
            .then(result => {
              if (Array.isArray(result) && result.length > 0){
                var raw = {deudor: usuario.deudor}
        
                axios.patch(window.$UrlOctavio + `/usuarios/${result[0].id}`, raw, {
                  headers: {
                    'Auth-Token':OCTAVIO_TOKEN
                  }
                })
                .then(response => {
                  if (response.data.id === result[0].id) {
                    res({message:'success', id: response.data.id})
                  }else{
                    res({message: 'error'})
                  }
                })
                .catch(error => {console.log('error', error); res({message: 'error',id:error})});
              }else{
                res({message: 'error'})
              }
            })
            .catch(error => {console.log('error', error); res({message: 'error',id:error})});
        })
      

    }

    render() {
      const columns = [
        {
            dataField: 'email',
            sort: true,
            text: 'Email',
            headerClasses: 'border-0',
            style: {
                width: "40%",
                wordBreak: "break-word"
            },
            uniqueData: "email"
        },
        {
          dataField: 'deudor',
          sort: true,
          text: 'Deudor',
          headerClasses: 'border-0',
          style: {
              width: "40%",
              wordBreak: "break-word"
          },
          uniqueData: "deudor"
        },
        {
          dataField: 'cliente',
          sort: true,
          text: 'Cliente',
          headerClasses: 'border-0',
          style: {
              width: "40%",
              wordBreak: "break-word"
          },
          uniqueData: "cliente"
        },
        {
            dataField: 'status',
            sort: true,
            
            text: 'Estado',
            headerClasses: 'border-0',
            style: {
                width: "10%"
            },
            uniqueData: "status",
            formatter: (cell, row) =>{
                switch (row.status) {
                    case 'success':
                        return <i className='fa fa-check-circle' style={{ color: 'green' }} title="Success" />;
                    case 'error':
                        return <i className='fa fa-times-circle' style={{ color: 'red' }} title="Error" />;
                    case 'loading':
                        return <Spinner animation="border" size="sm" variant="primary" />;
                    case 'pending':
                        return <i className='fa fa-exclamation-circle' style={{ color: '#007bff' }} title="Pending" />;
                }
            }
        },
        {
          dataField: 'respuesta',
          sort: true,
          text: 'Respuesta',
          headerClasses: 'border-0',
          style: {
              width: "40%",
              wordBreak: "break-word"
          },
          uniqueData: "respuesta"
        },
      ];
        return (
            <Fragment>
              <Navigation />
              {console.log(this.state)}
              {this.route()}
              {this.searchBar()}
              <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
                <div className="container mt-5">
                  <div className="row">
                    <div className="col-12">
                      <ToolkitProvider
                        keyField='id'
                        data={this.state.rows}
                        columns={columns}
                        search={<MySearch />}
                        // exportCSV
                      >
                        {
                          props => (
                            <Fragment>
                              <div className="search-group d-flex align-items-center justify-content-start mb-5" key={4898794654654}>
                                <label htmlFor="searchCustom" className="customlabelSearch">Buscar alumnos</label>
                                <MySearch
                                    {...props.searchProps}
                                />
                              </div>

                              <BootstrapTable
                                striped
                                hover
                                bordered={false}
                                headerClasses="header-class"
                                pagination={paginationFactory()}
                                {...props.baseProps}
                                // rowClasses={rowClasses}
                                // defaultSorted={defaultSortedBy}
                              />

                            </Fragment>
                          )
                        }
                      </ToolkitProvider>
                      <div className="col-12 d-flex flex-column align-items-center">
                        <button disabled={this.state.loading} className="btn btn-primary mt-4" onClick={(e) => this.handleSubmit(e)}>
                          {this.state.loading ? <LoaderSecondary/> : 'Actualizar'}
                        </button>
                      </div>
                    </div>          
                  </div>
                </div>
              </Animated>
            </Fragment>
        )
    }

}

export default routeDeudores;